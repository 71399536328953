<template>
	<div class="flex flex-col w-screen sm:w-auto px-4 py-6 sm:px-6 sm:py-12 gap-5">
		<h1 class="flex w-full text-xl h-6 mb-4 sm:mb-7 font-extrabold sm:text-2xl">Динамика заполнения анкет</h1>

		<div class="filter flex-col">
			<div class="flex">
				<div class="w-2/12 input-box items-center mr-8">
					<span class="text-gray-400 mb-2">Регион</span>
					<multiselect
						tag-placeholder=""
						placeholder=""
						:show-labels="false"
						v-model="filter.region"
						:options="regions"
						@input="changeVal('region', filter.region)"
						:searchable="false"
						:multiple="true"
						:limit="1"
						label="Name"
						track-by="Id"
						:close-on-select="false"
						@select="onSelect($event, regions, 'Id')"
						@remove="onRemove($event, regions, 'Id')"
					>
						<template slot="option" slot-scope="props">
							<input class="mr-1.5" type="checkbox" v-model="props.option.checked" @focus.prevent />
							<span>{{ props.option.Name }}</span>
						</template>
						<template slot="limit">
							<span></span>
						</template>
					</multiselect>
				</div>
				<div class="w-2/12 input-box items-center">
					<span class="text-gray-400">Срок заполнения</span>
					<div class="flex items-center">
						<span class="mr-1">Год</span>
						<multiselect
							tag-placeholder=""
							placeholder=""
							:disabled="filter.dateFrom !== null"
							:show-labels="false"
							v-model="filter.years"
							:options="years"
							:searchable="false"
							:multiple="true"
							:limit="2"
							label="year"
							track-by="year"
							:close-on-select="false"
							@select="onSelect($event, years, 'year')"
							@remove="onRemove($event, years, 'year')"
						>
							<template slot="option" slot-scope="props">
								<input class="mr-1.5" type="checkbox" v-model="props.option.checked" @focus.prevent />
								<span>{{ props.option.year }}</span>
							</template>
							<template slot="limit">
								<span></span>
							</template>
						</multiselect>
					</div>
				</div>
				<div class="w-2/12 input-box items-center ml-2">
					<div class="flex items-center mt-6">
						<span class="mr-1">Квартал</span>
						<multiselect
							tag-placeholder=""
							placeholder=""
							:disabled="filter.month.length > 0 || filter.dateFrom !== null"
							:show-labels="false"
							v-model="filter.quarter"
							:options="quarterList"
							:searchable="false"
							:multiple="true"
							:limit="1"
							label="quarter"
							track-by="quarter"
							:close-on-select="false"
							@select="onSelect($event, quarterList, 'quarter')"
							@remove="onRemove($event, quarterList, 'quarter')"
						>
							<template slot="option" slot-scope="props">
								<input class="mr-1.5" type="checkbox" v-model="props.option.checked" @focus.prevent />
								<span>{{ props.option.quarter }}</span>
							</template>
							<template slot="limit">
								<span></span>
							</template>
						</multiselect>
					</div>
				</div>
				<div class="w-2/12 input-box items-center ml-2">
					<div class="flex items-center mt-6">
						<span class="mr-1">Месяц</span>

						<multiselect
							tag-placeholder=""
							placeholder=""
							:disabled="filter.quarter.length > 0 || filter.dateFrom !== null"
							:show-labels="false"
							v-model="filter.month"
							:options="monthList"
							:searchable="false"
							:multiple="true"
							:limit="1"
							label="month"
							track-by="monthEng"
							:close-on-select="false"
							@select="onSelect($event, monthList, 'monthEng')"
							@remove="onRemove($event, monthList, 'monthEng')"
						>
							<template slot="option" slot-scope="props">
								<input class="mr-1.5" type="checkbox" v-model="props.option.checked" @focus.prevent />
								<span>{{ props.option.month }}</span>
							</template>
							<template slot="limit">
								<span></span>
							</template>
						</multiselect>
					</div>
				</div>
				<div class="w-auto input-box items-center ml-4">
					<div class="flex items-center mt-6">
						<span class="mr-1">Период от</span>
						<input onkeydown="return false" :min="dateMin" :max="dateNow" v-model="filter.dateFrom" ref="date1" type="date" />
					</div>
				</div>
				<div class="w-auto input-box items-center ml-4">
					<div class="flex items-center mt-6">
						<span class="mr-1">До</span>
						<input :min="!filter.dateFrom ? dateMin : filter.dateFrom" :max="dateNow" :disabled="!filter.dateFrom" v-model="filter.dateTo" type="date" />
					</div>
				</div>
			</div>
			<div class="flex items-center">
				<div class="w-60">
					<Button text="Сбросить фильтр" size="full" @click.native="reset" />
				</div>
			</div>
		</div>

		<!-- Таблица -->
		<TableRegion
			:resetYearQuarterMonth="resetYearQuarterMonth"
			:regions="filter.region"
			:years="filter.years"
			:quarter="filter.quarter"
			:month="filter.month"
			:dateFrom="filter.dateFrom"
			:dateTo="filter.dateTo"
		/>

		<TableIP
			:resetYearQuarterMonth="resetYearQuarterMonth"
			:regions="filter.region"
			:years="filter.years"
			:quarter="filter.quarter"
			:month="filter.month"
			:dateFrom="filter.dateFrom"
			:dateTo="filter.dateTo"
			id="tableIP"
			class="mt-10"
		/>
	</div>
</template>

<script>
import TableIP from './TableIP.vue';
import TableRegion from './TableRegion.vue';
import Button from '@/components/Button.vue';
import Multiselect from 'vue-multiselect';
import actions from '../store/actions';
import { compareAsc } from '@/store/store';

export default {
	name: 'Main',
	components: {
		TableRegion,
		TableIP,
		Button,
		Multiselect
	},
	data: () => ({
		currentYear: null,
		resetYearQuarterMonth: false,
		dateNow: new Date().toISOString().substr(0, 10),
		dateMin: new Date('2019-01-01').toISOString().substr(0, 10),
		regions: [],
		years: [],
		filter: {
			region: [{ Name: 'Все', Id: 0, checked: true }],
			years: [],
			quarter: [],
			month: [],
			dateFrom: null,
			dateTo: null
		},
		quarterList: [
			{
				id: 1,
				quarter: 'Квартал I',
				quarterEng: 'FirstQuarter',
				checked: false
			},
			{
				id: 2,
				quarter: 'Квартал II',
				quarterEng: 'SecondQuarter',
				checked: false
			},
			{
				id: 3,
				quarter: 'Квартал III',
				quarterEng: 'ThirdQuarter',
				checked: false
			},
			{
				id: 4,
				quarter: 'Квартал IV',
				quarterEng: 'FourthQuarter',
				checked: false
			}
		],
		monthList: [
			{
				id: 1,
				month: 'Январь',
				monthEng: 'January',
				checked: false
			},
			{
				id: 1,
				month: 'Февраль',
				monthEng: 'February',
				checked: false
			},
			{
				id: 1,
				month: 'Март',
				monthEng: 'March',
				checked: false
			},
			{
				id: 2,
				month: 'Апрель',
				monthEng: 'April',
				checked: false
			},
			{
				id: 2,
				month: 'Май',
				monthEng: 'May',
				checked: false
			},
			{
				id: 2,
				month: 'Июнь',
				monthEng: 'June',
				checked: false
			},
			{
				id: 3,
				month: 'Июль',
				monthEng: 'July',
				checked: false
			},
			{
				id: 3,
				month: 'Август',
				monthEng: 'August',
				checked: false
			},
			{
				id: 3,
				month: 'Сентябрь',
				monthEng: 'September',
				checked: false
			},
			{
				id: 4,
				month: 'Октябрь',
				monthEng: 'October',
				checked: false
			},
			{
				id: 4,
				month: 'Ноябрь',
				monthEng: 'November',
				checked: false
			},
			{
				id: 4,
				month: 'Декабрь',
				monthEng: 'December',
				checked: false
			}
		]
	}),
	watch: {
		'filter.dateFrom'(val) {
			if (!val) {
				this.filter.dateFrom = null;
				this.filter.dateTo = null;
			}

			if (val) {
				if (this.filter.dateFrom > this.filter.dateTo) this.filter.dateTo = null;
			}
		},
		'filter.dateTo'(val) {
			if (val) {
				this.resetYearQuarterMonth = true;
				this.filter.years = [];
				this.resetCheckedList('years');
				this.filter.quarter = [];
				this.resetCheckedList('quarterList');
				this.filter.month = [];
				this.resetCheckedList('monthList');
			}

			if (!val) {
				this.resetYearQuarterMonth = false;
			}
		}
	},
	methods: {
		...actions,
		changeVal(name, val) {
			let index = val.findIndex((f) => f.Id === 0 || f.id === 0);

			if (val.length > 1 && index === 0) {
				this.filter[name].splice(index, 1);
				for (let i = 0; i < this.regions.length; i += 1) {
					if (this.regions[i].Id === 0) this.regions[i].checked = false;
				}
			}

			if (index > 0 || val.length === 0) {
				if (name === 'region') {
					for (let i = 0; i < this.regions.length; i += 1) {
						if (this.regions[i].Id !== 0) this.regions[i].checked = false;
					}
					this.filter[name] = [{ Name: 'Все', Id: 0, checked: true }];
				}
			}
		},
		calculateWrite(length) {
			if (length === 1) return 'значение';
			if (length > 1 && length < 5) return 'значения';
			if (length > 4) return 'значений';
			return '';
		},
		onSelect(option, options, nameSearch) {
			let index = options.findIndex((item) => item[nameSearch] === option[nameSearch]);
			options[index].checked = true;
		},
		onRemove(option, options, nameSearch) {
			let index = options.findIndex((item) => item[nameSearch] === option[nameSearch]);
			options[index].checked = false;
		},
		resetCheckedList(name) {
			for (let i = 0; i < this[name].length; i += 1) {
				this[name][i].checked = false;
			}
		},
		reset() {
			for (let i = 0; i < this.years.length; i += 1) {
				if (+this.years[i].year === +this.currentYear) {
					this.years[i].checked = true;
				} else {
					this.years[i].checked = false;
				}
			}

			this.filter = {
				region: [{ Name: 'Все', Id: 0, checked: true }],
				years: [{ year: this.currentYear }],
				quarter: [],
				month: [],
				dateFrom: null,
				dateTo: null
			};
		}
	},
	created() {
		this.getSystemYearData().then((res) => {
			this.currentYear = res.data.currentYear;

			for (let i = 0; i < res.data.availableYears.length; i += 1) {
				this.years.push({
					year: String(res.data.availableYears[i]),
					checked: res.data.availableYears[i] === res.data.currentYear ? true : false
				});
			}

			this.onSelect({ year: String(res.data.currentYear), checked: false }, this.years, 'year');
			this.filter.years = [{ year: String(res.data.currentYear), checked: false }];
		});

		this.getRegions().then((res) => {
			for (let i = 0; i < res.data.length; i += 1) {
				res.data[i].checked = false;
			}
			res.data.unshift({ Name: 'Все', Id: 0, checked: true });
			this.regions = res.data.sort((a, b) => compareAsc(a.Name, b.Name));
		});
	}
};
</script>

<style>
input[type='date']:required:invalid::-webkit-datetime-edit {
	color: transparent;
}
input[type='date']:focus::-webkit-datetime-edit {
	color: black !important;
}
</style>
