<template>
	<div class="flex flex-col mt-0 pt-0">
		<div class="flex w-full py-3">
			<div @click="backStepDiagramm(historyStepDiagramm)" v-if="historyStepDiagramm.length > 1" class="flex items-center text-blue-500 mr-4 cursor-pointer">
				<Icons iconName="shevron" class="mr-1" />
				<span>Вернуться назад</span>
			</div>
			<span>
				Период:
				<span class="text-gray-400">
					{{ periodName }}
				</span>
			</span>
		</div>
		<div class="w-full font-semibold">
			{{ titleName }}
		</div>

		<div class="w-full">
			<VueApexCharts @click="updateChart" ref="chart" type="bar" :options="options" :series="seriesArr" />
		</div>
	</div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import actions from '../store/actions';
import ru from 'apexcharts/dist/locales/ru.json';
import Icons from '../../../../components/Icons.vue';

let clickPoint = {};

export default {
	props: {
		period: {
			type: String,
			default: () => ''
		},
		title: {
			type: String,
			default: () => ''
		},
		series: {
			type: Array,
			default: () => []
		},
		xaxisCategories: {
			type: Array,
			default: () => []
		},
		xaxisTitle: {
			type: String,
			default: () => ''
		},
		medicalId: {
			type: String,
			default: () => ''
		},
		year: {
			type: Array,
			default: () => []
		},
		typeDiagramm: {
			type: String,
			default: () => null
		},
		monthSelected: {
			type: Array,
			default: () => []
		},
		quarterSelected: {
			type: Array,
			default: () => []
		},
		currentObjForDiagramm: {
			type: Object,
			default: () => {}
		}
	},
	components: {
		VueApexCharts,
		Icons
	},
	data: () => ({
		setTypeDiagramm: null,
		step: 0,
		historyStepDiagramm: [],
		setQuarterAndMonth: {
			lastYear: 'lastYear',
			lastQuarter: 'lastQuarter',
			lastMonth: 'lastMonth',
			lastSevenDays: 'lastSevenDays',
			last24Hours: 'last24Hours',
			Квартал1: 'firstQuarter',
			Квартал2: 'secondQuarter',
			Квартал3: 'thirdQuarter',
			Квартал4: 'forthQuarter',
			Январь: 'january',
			Февраль: 'february',
			Март: 'march',
			Апрель: 'april',
			Май: 'may',
			Июнь: 'june',
			Июль: 'july',
			Август: 'august',
			Сентябрь: 'september',
			Октябрь: 'october',
			Ноябрь: 'november',
			Декабрь: 'december'
		},
		setPeriod: {
			lastYear: 'Последний год',
			lastQuarter: 'Последний квартал',
			lastMonth: 'Последний месяц',
			lastSevenDays: 'Последняя неделя',
			last24Hours: 'Последние 24 часа',
			Квартал1: 'Квартал I',
			Квартал2: 'Квартал II',
			Квартал3: 'Квартал III',
			Квартал4: 'Квартал IV',
			Январь: 'Январь',
			Февраль: 'Февраль',
			Март: 'Март',
			Апрель: 'Апрель',
			Май: 'Май',
			Июнь: 'Июнь',
			Июль: 'Июль',
			Август: 'Август',
			Сентябрь: 'Сентябрь',
			Октябрь: 'Октябрь',
			Ноябрь: 'Ноябрь',
			Декабрь: 'Декабрь'
		},
		firstQuarter: false,
		secondQuarter: false,
		thirdQuarter: false,
		forthQuarter: false,
		january: false,
		february: false,
		march: false,
		april: false,
		may: false,
		june: false,
		july: false,
		august: false,
		september: false,
		october: false,
		november: false,
		december: false,
		titleName: '',
		periodName: '',
		xaxisTitleName: '',
		seriesArr: [],
		xaxisCategoriesArr: [],
		options: {
			chart: {
				id: 'vuechart-nok',
				locales: [ru],
				defaultLocale: 'ru',
				events: {
					dataPointSelection(event, chartContext, config) {
						clickPoint = { event, chartContext, config };
					}
				}
			},
			plotOptions: {
				bar: {
					horizontal: false,
					columnWidth: '100%',
					endingShape: 'rounded',
					dataLabels: {
						position: 'top'
					}
				}
			},
			dataLabels: {
				enabled: true,
				formatter: function (val) {
					return `${val > 0 ? val : ''}`;
				},
				offsetY: -20,
				style: {
					fontSize: '10px',
					colors: ['#304758']
				}
			},
			stroke: {
				show: true,
				width: 4,
				colors: ['transparent']
			},
			xaxis: {
				categories: [],
				title: {
					text: ''
				}
			},
			yaxis: {
				title: {
					text: 'Количество'
				},
				max: function (max) {
					if (max < 30) return max + 1;

					return max + 20;
				}
			},
			colors: [
				function (obj) {
					if (obj.seriesIndex === 1) return '#E94B3D';
					return '#3377FF';
				}
			]
			// tooltip: {
			// 	y: {
			// 		formatter(value, obj) {
			// 			if (obj.seriesIndex === 1) return `${value}, тутлтип с текстом, объясняющим по какому методу определен всплеск`;
			// 			return value;
			// 		}
			// 	}
			// }
		}
	}),
	methods: {
		...actions,
		checkName(array, name, nameEng) {
			return array.map((m) => m[nameEng]).indexOf(name) !== -1;
		},
		updateChart(e) {
			let clickNameColumn = this.setQuarterAndMonth[this.xaxisCategoriesArr[clickPoint?.config?.dataPointIndex]];

			if (e.toElement.nodeName !== 'path' || !clickNameColumn) return;
			this.setTypeDiagramm = null;
			this.periodName = this.setPeriod[this.xaxisCategoriesArr[clickPoint?.config?.dataPointIndex]];

			this.getDataDiagrams({
				lastYear: false,
				lastQuarter: false,
				lastMonth: false,
				lastSevenDays: false,
				last24Hours: false,
				medicalId: this.medicalId,
				year: this.year[0].year,
				firstQuarter: clickNameColumn === 'firstQuarter' ? true : false,
				secondQuarter: clickNameColumn === 'secondQuarter' ? true : false,
				thirdQuarter: clickNameColumn === 'thirdQuarter' ? true : false,
				forthQuarter: clickNameColumn === 'forthQuarter' ? true : false,
				january: clickNameColumn === 'january' ? true : false,
				february: clickNameColumn === 'february' ? true : false,
				march: clickNameColumn === 'march' ? true : false,
				april: clickNameColumn === 'april' ? true : false,
				may: clickNameColumn === 'may' ? true : false,
				june: clickNameColumn === 'june' ? true : false,
				july: clickNameColumn === 'july' ? true : false,
				august: clickNameColumn === 'august' ? true : false,
				september: clickNameColumn === 'september' ? true : false,
				october: clickNameColumn === 'october' ? true : false,
				november: clickNameColumn === 'november' ? true : false,
				december: clickNameColumn === 'december' ? true : false,
				month: false
			}).then((res) => {
				this.xaxisCategoriesArr = res.data.xAxis;

				this.$refs.chart.updateOptions({
					xaxis: {
						categories: res.data.xAxis,
						title: {
							text: ''
						}
					}
				});
				this.$refs.chart.updateSeries([
					{
						name: 'Анкет',
						data: res.data.yAxis
					},
					{
						name: 'Подозрительных анкет',
						data: res.data.yAxisStrangeCount
					}
				]);

				this.historyStepDiagramm.push({
					step: (this.step += 1),
					period: this.periodName,
					filter: {
						lastYear: false,
						lastQuarter: false,
						lastMonth: false,
						lastSevenDays: false,
						last24Hours: false,
						medicalId: this.medicalId,
						year: this.year[0].year,
						firstQuarter: clickNameColumn === 'firstQuarter' ? true : false,
						secondQuarter: clickNameColumn === 'secondQuarter' ? true : false,
						thirdQuarter: clickNameColumn === 'thirdQuarter' ? true : false,
						forthQuarter: clickNameColumn === 'forthQuarter' ? true : false,
						january: clickNameColumn === 'january' ? true : false,
						february: clickNameColumn === 'february' ? true : false,
						march: clickNameColumn === 'march' ? true : false,
						april: clickNameColumn === 'april' ? true : false,
						may: clickNameColumn === 'may' ? true : false,
						june: clickNameColumn === 'june' ? true : false,
						july: clickNameColumn === 'july' ? true : false,
						august: clickNameColumn === 'august' ? true : false,
						september: clickNameColumn === 'september' ? true : false,
						october: clickNameColumn === 'october' ? true : false,
						november: clickNameColumn === 'november' ? true : false,
						december: clickNameColumn === 'december' ? true : false,
						month: false
					}
				});
			});
		},
		backStepDiagramm(arrayHistory) {
			arrayHistory.splice(arrayHistory.length - 1, 1);
			let currentData = arrayHistory[arrayHistory.length - 1];

			this.periodName = currentData.period;

			this.getDataDiagrams(currentData.filter).then((res) => {
				this.xaxisCategoriesArr = res.data.xAxis;

				this.$refs.chart.updateOptions({
					xaxis: {
						categories: res.data.xAxis,
						title: {
							text: ''
						}
					}
				});
				this.$refs.chart.updateSeries([
					{
						name: 'Анкет',
						data: res.data.yAxis
					},
					{
						name: 'Подозрительных анкет',
						data: res.data.yAxisStrangeCount
					}
				]);
			});
		}
	},
	mounted() {
		this.setTypeDiagramm = this.typeDiagramm;
		this.periodName = this.period;
		this.titleName = this.title;
		this.xaxisTitleName = this.xaxisTitle;
		this.seriesArr = this.series;
		this.xaxisCategoriesArr = this.xaxisCategories;

		this.$refs.chart.updateOptions({
			xaxis: {
				categories: this.xaxisCategoriesArr,
				title: {
					text: this.xaxisTitleName
				}
			}
		});

		// Сохраняю массив шагов графика что бы откатываться на шаг назад
		this.historyStepDiagramm.push({
			step: (this.step += 1),
			period: this.period,
			filter: this.currentObjForDiagramm
		});
	}
};
</script>

<style>
.apexcharts-menu {
	min-width: 114px !important;
}
</style>
