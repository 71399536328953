<template>
	<div class="flex flex-col">
		<div class="text-left mb-4 text-gray-500">
			{{ nameMo }}
		</div>
		<div class="t-overflow rounded-t-lg overflow-x-auto h-auto bg-white filter shadow-default">
			<table class="w-full">
				<thead>
					<tr>
						<th class="w-10/12 px-6 py-3 border-b border-gray-200 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Причина</th>
						<th class="w-2/12 text-center px-6 py-3 border-b border-gray-200 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Кол-во</th>
					</tr>
				</thead>
				<tBody>
					<tr v-for="(item, ind) in dataStrangeAnkets" :key="ind">
						<td class="px-6 py-4 text-sm font-normal whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
							{{ item.description }}
						</td>
						<td class="px-6 text-center py-4 text-sm font-normal whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
							{{ item.count }}
						</td>
					</tr>
				</tBody>
			</table>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		dataStrangeAnkets: {
			type: Array,
			default: () => []
		},
		nameMo: {
			type: String,
			default: () => ''
		}
	}
};
</script>
